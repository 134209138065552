<template>
  <div v-if="data.attendanceId">

    <div class="col-12 flex row justify-center items-center" v-if="loadingTabulation">
      <q-spinner-hourglass color="primary" size="2rem"/>
    </div>

    <div class="row form-tabulation q-pr-xs" v-if="!loadingTabulation">

      <div class="col-12" >

        <div v-show="!isForm" class="row flex justify-center q-mt-md">
          <div class="col-12 flex justify-center">
            <q-icon name="search_off" size="60px" style="color: #0081dd;"/>
          </div>
          <div class="col-12 flex justify-center q-mt-md q-mb-lg">
            <p style="font-size: 15px;"><b>Não existe formulário cadastrado ou habilidato para a fila.</b></p>
          </div>
        </div>

        <div class="q-py-md" v-show="isForm">
          <label style="font-size: 25px;">Formulário</label>
        </div>

        <q-form ref="formTabulation" class="q-gutter-md">

          <div v-for="param in params_attendance" :key="param.parameterId">
            <div class="row" v-if="param.parameterType == 'input'">
              <div class="col-12 q-mb-sm">
                <label>{{ param.parameterName }}</label>
              </div>
              <div class="col-12">
                <q-input 
                    square outlined
                    dense 
                    bottom-slots 
                    type="text" 
                    bg-color="white" 
                    v-model="param.parameterValue">
                </q-input>
              </div>
            </div>

            <div class="row" v-if="param.parameterType == 'select'">
              <div class="col-12 q-mb-sm">
                <label>{{ param.parameterName }}</label>
              </div>
              <div class="col-12">
                <q-select 
                    square outlined 
                    dense
                    emit-value 
                    map-options
                    v-model="param.parameterValue"
                  :options="filterOptionsParam(param.parameterOptions)">
                </q-select>
              </div>
            </div>

            <div class="row q-mt-lg" v-if="param.parameterType == 'radio'">
              <div class="col-12 q-mb-sm">
                <label>{{ param.parameterName }}</label>
              </div>
              <div class="col-12">
                <q-option-group
                    square outlined 
                    v-model="param.parameterValue" 
                    inline
                    color="primary"
                  :options="filterOptionsParam(param.parameterOptions)">
                </q-option-group>
              </div>
            </div>

            <div class="row" v-if="param.parameterType == 'date'">
              <div class="col-12 q-mb-sm">
                <label>{{ param.parameterName }}</label>
              </div>
              <div class="col-12">
                <q-input 
                    square outlined 
                    dense
                    v-model="param.parameterValue">
                  <template v-slot:prepend>
                    <q-icon name="event" class="cursor-pointer">
                      <q-popup-proxy cover transition-show="scale" transition-hide="scale">
                        <q-date v-model="param.parameterValue" mask="YYYY-MM-DD HH:mm">
                          <div class="row items-center justify-end">
                            <q-btn v-close-popup label="Close" color="primary" flat />
                          </div>
                        </q-date>
                      </q-popup-proxy>
                    </q-icon>
                  </template>

                  <template v-slot:append>
                    <q-icon name="access_time" class="cursor-pointer">
                      <q-popup-proxy cover transition-show="scale" transition-hide="scale">
                        <q-time v-model="param.parameterValue" mask="YYYY-MM-DD HH:mm" format24h>
                          <div class="row items-center justify-end">
                            <q-btn v-close-popup label="Close" color="primary" flat />
                          </div>
                        </q-time>
                      </q-popup-proxy>
                    </q-icon>
                  </template>
                </q-input>
              </div>
            </div>

            <div class="row" v-if="param.parameterType == 'text-area'">
              <div class="col-12 q-mb-sm">
                <label>{{ param.parameterName }}</label>
              </div>
              <div class="col-12">
                <q-input 
                    square outlined
                    dense 
                    type="textarea" 
                    bg-color="white" 
                    v-model="param.parameterValue">
                </q-input>
              </div>
            </div>

            <div class="row q-mt-lg" v-if="param.parameterType == 'money'">
              <div class="col-12 q-mb-sm">
                <label>{{ param.parameterName }}</label>
              </div>
              <div class="col-12">
                <money3 class="full-width q-pa-sm" v-model="param.parameterValue" v-bind="config"></money3>
              </div>
            </div>

          </div>
        </q-form>

        <div v-show="isForm" class="buttons-container q-mt-xs q-mb-lg">
          <div>
            <q-btn unelevated rounded color="primary" size="0.72rem" label="Salvar formulário" @click="saveForm()"
              class="q-mt-md" />
          </div>
        </div>

        <q-separator class="q-mt-sm"></q-separator>

        <div v-show="!isTabulation" class="row flex justify-center q-mt-xl">
          <div class="col-12 flex justify-center">
            <q-icon name="search_off" size="60px" style="color: #0081dd;"/>
          </div>
          <div class="col-12 flex justify-center q-mt-md q-mb-lg">
            <p style="font-size: 15px;"><b>Não existe tabulação cadastrada ou habilitada para a fila.</b></p>
          </div>
        </div>

        <div class="col-12 q-mt-lg q-mb-sm" v-show="isTabulation">
          <label style="font-size: 25px;">Tabulação</label>

          <div v-for="element, index in tabulationInProcess">
          <div class="col-12 q-mt-md">
            <label>Descrição {{ index+1 }}</label>
          </div>
          <div class="col-12 q-mt-sm">
            <q-select square outlined 
                      dense
                      emit-value 
                      map-options
                      v-model="element.selectedValue" 
                      :options="element.options"
                      @update:model-value="updateTabulationInProcess(element.selectedValue)">
            </q-select>
          </div>
        </div>
        <div class="buttons-container q-mt-xs q-mb-lg">
          <div>
            <q-btn unelevated rounded color="primary" size="0.72rem" label="Salvar tabulação" class="q-mt-md"
              @click="saveTabulation()" />
          </div>
        </div>
        </div>

        
      </div>

    </div>
  </div>

</template>
<script setup>
import {ref, onMounted, watch} from 'vue';
import {useQuasar} from 'quasar';
import TreeServices from '@/services/TreeServices';
import ParamService from '@/services/ParamService';

const props = defineProps({
  data: Object,
  tabulation_queue: Object,
  tabulation_attendance:{
    type:Object,
    required:false
  },
  params_attendance: Object,
  isTicket: {
    default: false,
    type: Boolean
  },
  loading: {
    type: Boolean
  },
  isOpen: {
    type: Boolean,
    required: false
  }
})
let loadingTabulation = ref(true);
const emit = defineEmits(['tabulation_saved','emitTabulation','emitForm']);
const $q = useQuasar();
const _treeServices = new TreeServices();
const _paramsServices = new ParamService();
const config = {
  min: 0,
  decimal: ',',
  thousands: '.',
  prefix: 'R$ ',
  precision: 2,
  masked: false
}

let tabulationInformation = ref({})
let currentBranch = ref([])
let tabulationTree = ref([]) 
let tabulationInProcess = ref([{
  selectedValue: '',
  options: []
}])

let formTabulation = ref(null);
let isTabulation = ref(true);
let isForm = ref(props.params_attendance.length > 0 ? true : false);



async function saveTabulation(){
  let filteredTabulation = tabulationInProcess.value.filter(answer =>{  
    return answer['selectedValue'] 
  })

  if(filteredTabulation.length > 0){
    let answerList = []

    filteredTabulation.forEach((answer)=>{
      answerList.push(answer['selectedValue'])
    })

    let idTabulation = await getIdTabulation(props.data.idQueue, answerList);
    
    await updateTabulation(props.data.attendanceId, idTabulation);

    emit('emitTabulation', {
      "idAttendance": props.data.attendanceId,
      "idTabulation": idTabulation,
      "savedTabulation":answerList
    });


    _savedTabulation();
    
  }else{ 
    notification('red', 'Preencha a Tabulação')
  }  
}

//BUSCAR O ID DE TABULAÇÃO DE ACORDO COM OS MOTIVOS
async function getIdTabulation(idQueue, listDescription){
  let idTabulation;
  await _treeServices.getIdTabulation(idQueue, listDescription).then((response)=>{
    idTabulation = response.data;
  }).catch(err => {
    return 0;
  });
  return idTabulation;
}

//SALVAR O ID DE TABULAÇÃO NO BANCO NA TABELA DE TICKET
async function updateTabulation(attendanceId, idTabulation){

  if(props.isTicket){
    await _treeServices.updateTabulation(attendanceId, idTabulation).then((response)=>{
      if(response){
        notification('green', 'Tabulação salva com sucesso.')
      }else{
        notification('red', 'Ocorreu um erro, tente novamente.')
      }
    }).catch(err => {
      let message = err.response.data ? err.response.data : err.message
      notification("red",message)
    });
  }else{
    await _treeServices.updateTabulationChat(attendanceId, idTabulation).then((response)=>{
      if(response){
        notification('green', 'Tabulação salva com sucesso.')
      }else{
        notification('red', 'Ocorreu um erro, tente novamente.')
      }
    }).catch(err => {
      let message = err.response.data ? err.response.data : err.message
      notification("red",message)
    });
  }


}

//SALVAR OU FAZER UPDATE DO FORMULÁRIO NO BANCO
async function saveForm(){
  $q.loading.show();
  loadingTabulation.value = true;

  let paramsForm = props.params_attendance.filter((param)=>{
      if(param.parameterValue != null){
      return param;
    }
  });

  let attendanceFlag = props.isTicket?  'ticket' : 'attendance';
  
  await _paramsServices.saveTabulationAnswers(attendanceFlag, props.data.attendanceId, paramsForm).then((response)=>{
    response? notification("green","Formulário salvo com sucesso.") : notification("red","Ocorreu um erro, tente novamente.");
  }).catch((error)=>{
    let message = error.response.data ? error.response.data : error.message
    notification("red",message)
  })
  
  $q.loading.hide();
  loadingTabulation.value = false;

}

//PREENCHER O QUE TIVER OPÇÃO NO FORMULÁRIO, RADIO, SELECT E ETC
function filterOptionsParam(options){
  let optionsParamSelect = [];

  options.forEach((option)=>{
    optionsParamSelect.push({
      label: option.parameterOptionValue,
      value: option.parameterOptionValue
    });
  });

  return optionsParamSelect;
}

function notification(color, message){
  $q.notify({
        message: `${message}`,
        color: `${color}-8`,
        position: "top"
      });
}

function populateTabulationInProcessStep(step){
  let options=[]
  step['children'].forEach(element=>{
    options.push(element['value'])  
  })       
  if(options.length > 0){
    tabulationInProcess.value.push({
      selectedValue:'',
      options:options
    })
  }
}

function populateExistingTabulation(step, existingValue){
  let options=[]
  step['children'].forEach(element=>{
    options.push(element['value'])  
  })
  let currentLasIndex = tabulationInProcess.value.length - 1
  tabulationInProcess.value[currentLasIndex]['selectedValue'] = existingValue
  if(options.length > 0){
    tabulationInProcess.value.push({
      selectedValue:existingValue,
      options:options
    })
  }
}

function adjustTabulationInProcess(positionIndex, treeSpeedster){
  tabulationInProcess.value = tabulationInProcess.value.filter((element,index) =>{
        if(index < positionIndex + 1){
          return element;
        }
      })

      tabulationInProcess.value.forEach(answer =>{
        treeSpeedster =  Object.values(treeSpeedster[0]['children']).filter(node=>{
          if(node['value'] == answer['selectedValue']){
            return node
          }
        })
      })
      populateTabulationInProcessStep(treeSpeedster[0])
}

function updateTabulationInProcess(value){
  let treeSpeedster = {...tabulationTree.value};
  
  if(tabulationInProcess.value.length > 1 ){

    let positionIndex = tabulationInProcess.value.findIndex(answer => answer['selectedValue'] == value);
    
    if(positionIndex > -1){
      adjustTabulationInProcess(positionIndex, treeSpeedster)
    }
  }else{
    
    currentBranch.value = Object.values(currentBranch.value[0]['children']).filter(node=>{
      if(node['value']== value){
        populateTabulationInProcessStep(node)
        return node
      }
    })
  }
}

function populateCurrentBranch(newTree){
  tabulationInformation.value = {...newTree};
  
  currentBranch.value = Object.values(tabulationInformation.value).filter(item =>{
    if(typeof(item) == 'object'){
      return item
    };
  })

  tabulationTree.value = {...currentBranch.value}

  Object.values(currentBranch.value[0]['children']).forEach(element=>{
    tabulationInProcess.value[0].options.push(element['value'])
  }) 
}

function resetTabulation(tree){
  loadingTabulation.value=true;
    tabulationInProcess.value = [{
      selectedValue: '',
      options: []
    }]
    populateCurrentBranch(tree)
    loadingTabulation.value=false;
}


function _savedTabulation() {
  emit('tabulation_saved');
}

onMounted(()=>{
  if(props.tabulation_queue['child']){
    resetTabulation(props.tabulation_queue)
  }
  loadingTabulation.value=false;

  
  if(props.tabulation_attendance.length > 0){
    props.tabulation_attendance.forEach((value)=>{
      currentBranch.value = Object.values(currentBranch.value[0]['children']).filter(node=>{
        if(node['value']== value){
          populateExistingTabulation(node, value)
          return node
        }
      })
    })
  }
})

watch(()=>props.tabulation_queue, (newValue,oldValue)=>{
  if(newValue['child']){
    resetTabulation(newValue)
    if(props.tabulation_attendance.length > 0){
      props.tabulation_attendance.forEach((value)=>{
    
        currentBranch.value = Object.values(currentBranch.value[0]['children']).filter(node=>{
          if(node['value']== value){
            populateExistingTabulation(node, value)
            return node
          }
        })
      })
    }
  }
})

</script>
<style lang="scss" scoped>
.card-form-tabulation {
  max-height: 75vh;
  overflow-y: hidden;
}

.form-tabulation {
  max-height: 75vh;
  overflow-y: auto;
}

.buttons-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: end;
}
</style>