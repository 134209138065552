<template>
  <div class="login-container col-12 spacing-login">
    <div class="login-header flex row col-12 q-my-md justify-center">
      <q-img src="@/assets/images/logo.svg" spinner-color="white" style="width: 60%;" />
    </div>
    <div class="login-body flex row col-12  q-my-md justify-center">
      <form @submit.prevent="authenticate" class="form-login row">
        <div class="col-12 q-mt-md">
          <q-input outlined v-model="login" bg-color="white" label="Usuário" id="username" dense
            input-class="input-default" />
        </div>
        <div class="col-12 q-mt-md">
          <q-input type="password" bg-color="white" outlined v-model="password" label="Senha" id="password" dense
            input-class="input-default" />
        </div>
        <div class="flex row col-12 justify-end q-pt-lg">
          <button type="submit" class="btn-login" v-if="!loading">Login</button>
          <button class="btn-login" v-else>
            <q-spinner-hourglass color="white" size="1.5rem" />
          </button>
        </div>
      </form>
    </div>
    <div class="login-footer q-pa-md">
      <div class="flex row justify-end">
        <q-img src="@/assets/images/powered-by-wtf.svg" spinner-color="white" width="150px" />
      </div>
    </div>
  </div>

  <q-dialog v-model="model" persistent>
      <q-card>
        <q-card-section>
          
          <b style="color:red" class="text-h6"><q-icon name="warning" color="red" size="xs" /> Atenção:</b>
      </q-card-section>

        <q-card-section class="row items-center">
          <span class="q-ml-sm" style="font-size:1.15rem">Identificamos outra sessão do sistema ativa para este usuário.</span>
          <span class="q-ml-sm" style="font-size:1.15rem">Como gostaria de prosseguir?</span>
        </q-card-section>

        <q-card-actions align="right">
          <q-btn color="grey-4" text-color="black" label="Sair" v-close-popup />
          <q-btn color="secondary" label="Entrar " @click="logoutUserAnotherSession" v-close-popup />
        </q-card-actions>

        <q-card-section class="row items-center">
          <span class="q-ml-sm">[ Entrar ] Essa ação irá desconectar a outra sessão que está aberta.</span>
          <span class="q-ml-sm">[ Sair ] Essa ação mantêm a outra sessão em aberto, para você continuar seu trabalho dela lá.</span>
      </q-card-section>
      </q-card>
    </q-dialog>
</template>

<script>
import { ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { Cookies, useQuasar } from 'quasar';
import AuthServices from '@/services/AuthServices'
export default {
  name: 'LoginView',
  setup() {
    const store = useStore();
    const router = useRouter();
    const _authServices = new AuthServices();
    const $q = useQuasar();
    let login = ref("");
    let password = ref("");
    let userId = ref("");
    let loading = ref(false);
    let model = ref(false);

    function authenticate() {
      loading.value = true
      _authServices.auth({ "login": login.value, "password": password.value })
      .then(response => {
        
        response = response.data;
        if(response.data.data_login){
          response.data.data_login = manipulateDateTime(response.data.data_login);
        }
        localStorage.setItem("token_plataforma_digital", response.token);
        store.commit("setUserdata", response.data)
        store.commit("setMenu", response.menu)
        store.commit("setPermission", response.permissions)
        if(response.data.id_empresa == 16){
          store.commit("setCompanyParameters", response.companyParameters)
        }
        loading.value = false
        router.push({ "name": "admin" });
      }).catch(err => {
        loading.value = false
    
        if(err.response.status == 422){
          model.value = true;
          userId.value = err.response.data;
          return;
        }
        
        
        $q.notify({
          message: `Error: ${err.response.data ? err.response.data : err}`,
          color: "red-8",
          position: "top"
        })
      })

    }

    function manipulateDateTime(currentDate){
      let initialAttendanaceDate = new Date(currentDate)
      let formattedAttendanceDate = initialAttendanaceDate.toLocaleString('pt-BR', { timeZoneName: 'shortGeneric' });
      formattedAttendanceDate = formattedAttendanceDate.replace('BRT','');
      formattedAttendanceDate = formattedAttendanceDate.trim();
      return formattedAttendanceDate;
    }


    async function logoutUserAnotherSession(){
      await _authServices.logoutAnotherSession(userId.value);
      await authenticate();
    }

    return {
      login,
      password,
      loading,
      model,
      authenticate,
      logoutUserAnotherSession
    }
  }
}
</script>
<style lang="scss" scoped>
.login-container {
  position: absolute;
  // left: 8rem;
  top: 15vh;
  color: #fff;
  // width: 25%;
  min-height: 60%;
  max-height: 80%;
 
  background: linear-gradient(180deg, hsla(0, 0%, 100%, .2), hsla(0, 0%, 100%, 0)), radial-gradient(133.17% 32.16% at 59.58% 51.43%, rgba(237, 241, 250, .696) 0, rgba(237, 241, 250, .435) 100%);
  box-shadow: 4px 0 4px rgb(0 0 0 / 5%);
  border-radius: 15px;
}

.form-login {
  width: 90%;

  .btn-login {
    background: #385299;
    border-radius: 17.58px;
    color: #fff;
    width: 30%;
    height: 4vh;
    border: none;
    cursor: pointer;
  }
  

  // .btn-login:hover {
  //   background: #fff;
  //   color: #385299;
  // }
}
@media (min-width: $breakpoint-md-min)
{
  .spacing-login {
    left: 8rem;
}
}

@media (max-width: $breakpoint-sm-max)
{
  .spacing-login {
    margin-inline: 1rem;
}
}

</style>
