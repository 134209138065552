import http from '../http-common'

export default class TreeServices {

    async addTree(data){
        let res = await http.post(`v1/tree/add-tree`, data);
        return res;
    }

    async importTree(treeId, data){
        let res = await http.post(`v1/tree/${treeId}/import-tree`, data);
        return res;
    }

    async getAllTree(companyId){
        let res = await http.get(`v1/tree/${companyId}/get-tree`);
        return res;
    }

    async getTabulationByIdQueue(queueId, ticketId){
        let res = await http.get(`v1/tree/${queueId}/get-tabulation-queue?ticketId=${ticketId}`);
        return res;
    }

    async getTabulationByIdQueueChat(queueId, attendenceId){
        let res = await http.get(`v1/tree/${queueId}/get-tabulation-queue-chat?attendenceId=${attendenceId}`);
        return res;
    }

    async nextStepTabulation(queueId, descriptions){
        let res = await http.post(`v1/tree/next-step-tabulation?queueId=${queueId}`, descriptions);
        return res;
    }

    async getIdTabulation(queueId, descriptions){
        let res = await http.post(`v1/tree/get-id-tabulation?queueId=${queueId}`, descriptions);
        return res;
    }

    async getTabulation(tabulationId){
        let res = await http.get(`v1/tree/${tabulationId}/get-tabulation`);
        return res;
    }

    async updateTabulation(ticketId, tabulationId){
        let res = await http.post(`v1/tree/${ticketId}/update-tabulation?tabulationId=${tabulationId}`);
        return res;
    }

    async updateTabulationChat(attendenceId, tabulationId){
        let res = await http.post(`v1/tree/${attendenceId}/update-tabulation-chat?tabulationId=${tabulationId}`);
        return res;
    }

    async updateTree(data){
        let res = await http.put(`v1/tree/update-tree`, data);
        return res;
    }

    async updateStatusTree(treeId, status){
        let res = await http.put(`v1/tree/${treeId}/update-status-tree?status=${status}`);
        return res;
    }

    async clearIdTabulation(ticketId){
        let res = await http.put(`v1/tree/${ticketId}/clear-tabulation`);
        return res;
    }

    async clearIdTabulationChat(attendenceId){
        let res = await http.put(`v1/tree/${attendenceId}/clear-tabulation-chat`);
        return res;
    }

    async getTabulationThroughProtocol(protocol){
        let res = await http.get(`v1/tree/get-tabulation/${protocol}`);
        return res.data;
    }

    async addVoiceTabulationProfiling(attendanceId, tabulationProfilingÌd){
        let res = await http.put(`v1/tree/add-tabulation-profiling/${attendanceId}/${tabulationProfilingÌd}`)
        return res;
    }
}